import { api } from './api';
import { generateFilter, newFilterGenerate, prepareFilters, prepareSort, stringOperators } from 'helpers/changeFilterValueToFilterRequest';

const NAMES_FOR_FILTER_ACTIVITY_LOG = {
    ExternalId: 'WorkItem/ExternalId',
    Project: 'WorkItem/Project/Id',
    AlternativeName: 'WorkItem/Project/AlternativeName',
    WorkItemName: 'WorkItem/Name',
    Organization: 'WorkItem/Project/Organization/Id',
    Task: 'WorkItem/Id'
};
const NAMES_FOR_FILTER_ACTIVITY_LOG_TO_STRING = {
    Project: 'WorkItem/Project/AlternativeName',
    Organization: 'WorkItem/Project/Organization/Name',
    Task: 'WorkItem/Name'
};

const NAMES_FOR_SORT_ACTIVITY_LOG = {
    ExternalId: 'WorkItem/ExternalId',
    Project: 'WorkItem/Project/Name',
    AlternativeName: 'WorkItem/Project/AlternativeName',
    WorkItemName: 'WorkItem/Name',
    Organization: 'WorkItem/Project/Organization/Name',
    Task: 'WorkItem/Name'
};

const NAMES_FOR_FILTER_WEEKLY_LOG_NO_WORK_ITEMS = {
    ExternalId: 'WorkItem/ExternalId',
    Project: 'WorkItem/Project/Id',
    AlternativeName: 'WorkItem/Project/AlternativeName',
    WorkItem: 'WorkItem/Id',
    WorkItemName: 'WorkItem/Name',
    User: 'User/FullName'
};

const NAMES_FOR_FILTER_WEEKLY_LOG_NO_WORK_ITEMS_TO_STRING = {
    Project: 'WorkItem/Project/AlternativeName',
    WorkItem: 'WorkItem/Name',
};

const NAMES_FOR_SORT_WEEKLY_LOG_NO_WORK_ITEMS = {
    ExternalId: 'WorkItem/ExternalId',
    Project: 'WorkItem/Project/AlternativeName',
    AlternativeName: 'WorkItem/Project/AlternativeName',
    WorkItem: 'WorkItem/Name',
    WorkItemName: 'WorkItem/Name',
    User: 'User/FullName'
};

const NAMES_FOR_FILTER_WEEKLY_LOG = {
    ExternalId: 'ExternalId',
    Project: 'Project/Id',
    AlternativeName: 'Project/AlternativeName',
    WorkItem: 'Id'
};

const NAMES_FOR_FILTER_WEEKLY_LOG_TO_STRING = {
    Project: 'Project/AlternativeName',
    WorkItem: 'Name'
};

const NAMES_FOR_SORT_WEEKLY_LOG = {
    ExternalId: 'ExternalId',
    Project: 'Project/AlternativeName',
    AlternativeName: 'Project/AlternativeName',
    WorkItem: 'Name'
};

export const activityLogApi = api.injectEndpoints({
    tagTypes: ['Transaction'],
    endpoints: (builder) => ({
        getTransactions: builder.query({
            query: ({ skip = 0, limit = 20, dateRange, filterValue = null, userId, sortInfo, columns = null }) => {
                let filter = '';
                let dateFilter = '';
                let sort = 'CreatedAt desc';
                let params = {};

                if (dateRange) {
                    dateFilter =
                        dateRange.from === dateRange.to
                            ? `TransDate eq ${dateRange.to} and UserId eq ${userId}`
                            : `(TransDate ge ${dateRange.from} and TransDate le ${dateRange.to} and UserId eq ${userId})`;
                    filter += dateFilter;
                }

                if (filterValue?.items?.some((item) => !!item.value)) {
                    let filterValueRes = filterValue?.items?.map((item) => {
                        let value = Array.isArray(item.value)
                            ? item.value.map((el) => el[NAMES_FOR_FILTER_ACTIVITY_LOG[item.field] ? 'id' : 'label'] || 'label')
                            : item.value;
                        if (value?.id) {
                            value = value.id;
                        }
                        if (Array.isArray(item.value) && item.value.length === 1) {
                            value = value.at(0)[NAMES_FOR_FILTER_ACTIVITY_LOG[item.field] ? 'id' : 'label'];
                        }

                        let field = NAMES_FOR_FILTER_ACTIVITY_LOG[item.field] ? NAMES_FOR_FILTER_ACTIVITY_LOG[item.field] : item.field

                        if (Object.keys(stringOperators).includes(item.operator) && NAMES_FOR_FILTER_ACTIVITY_LOG_TO_STRING[item.field]) {
                            field = NAMES_FOR_FILTER_ACTIVITY_LOG_TO_STRING[item.field]
                        }

                        return {
                            ...item,
                            field: field,
                            value: value
                        };
                    });

                    let filterRes = newFilterGenerate({
                        filterValue: filterValueRes,
                        logicOperator: filterValue.logicOperator,
                        columns: columns
                    });
                    if (filterRes) {
                        filter += ` and ${filterRes}`;
                    }
                }

                if (sortInfo && Object.keys(sortInfo).length > 0) {
                    sort = `${NAMES_FOR_SORT_ACTIVITY_LOG[sortInfo.field] ? NAMES_FOR_SORT_ACTIVITY_LOG[sortInfo.field] : sortInfo.field} ${sortInfo.sort
                        }`;
                }

                params = {
                    $expand: 'WorkItem($expand=Project($select=Name,Id,AlternativeName;$expand=Organization($select=Name,Id)))',
                    $top: limit,
                    $skip: skip,
                    $filter: filter.trim()
                };

                if (sort) params['$orderby'] = sort;

                return {
                    url: `Transactions`,
                    params
                };
            },

            providesTags: [{ type: 'Transaction' }]
        }),

        getTransactionsWithoutWorkItem: builder.query({
            query: ({ dateRange, userId, ...args }) => {
                let filter = '';
                let dateFilter = '';
                let params = {}
                if (dateRange) {
                    dateFilter =
                        dateRange.from === dateRange.to
                            ? `(TransDate eq ${dateRange.to} and UserId eq ${userId})`
                            : `(TransDate ge ${dateRange.from} and TransDate le ${dateRange.to} and UserId eq ${userId})`;
                    filter += `${dateFilter} and WorkItemId eq null`;
                }

                let filters = prepareFilters({
                    filterValue: args?.filterValue,
                    columns: args?.columns,
                    names: NAMES_FOR_FILTER_WEEKLY_LOG_NO_WORK_ITEMS,
                    namesString: NAMES_FOR_FILTER_WEEKLY_LOG_NO_WORK_ITEMS_TO_STRING
                });
                if (filters) {
                    filter += ` and ${filters}`;
                }
                if (filter) {
                    params['$filter'] = filter;
                }

                let sort = prepareSort({
                    sortInfo: args?.sortInfo,
                    columns: args?.columns,
                    names: NAMES_FOR_SORT_WEEKLY_LOG_NO_WORK_ITEMS
                });
                if (sort) {
                    params['$orderby'] = sort;
                }
                return {
                    url: `Transactions`,
                    params
                };
            },
            providesTags: [{ type: 'Transaction' }]
        }),

        usersLogTrans: builder.query({
            query: ({ skip = 0, limit = 20, dateRange, userId, ...args }) => {
                let filter = '';
                let dateFilter = '';
                let params = {
                    // $top: limit,
                    $skip: skip, $expand: 'WorkItem($expand=Project($select=Name,AlternativeName))'
                };
                if (dateRange) {
                    dateFilter =
                        dateRange.from === dateRange.to
                            ? `(TransDate eq ${dateRange.to})`
                            : `(TransDate ge ${dateRange.from} and TransDate le ${dateRange.to})`;
                    filter += `${dateFilter} and WorkItemId ne null`;
                }

                let filters = prepareFilters({
                    filterValue: args?.filterValue,
                    columns: args?.columns,
                    names: NAMES_FOR_FILTER_WEEKLY_LOG_NO_WORK_ITEMS,
                    namesString: NAMES_FOR_FILTER_WEEKLY_LOG_NO_WORK_ITEMS_TO_STRING
                });
                if (filters) {
                    filter += ` and ${filters}`;
                }
                if (filter) {
                    params['$filter'] = filter;
                }

                let sort = prepareSort({
                    sortInfo: args?.sortInfo,
                    columns: args?.columns,
                    names: NAMES_FOR_SORT_WEEKLY_LOG_NO_WORK_ITEMS
                });
                if (sort) {
                    params['$orderby'] = sort;
                }
                return {
                    url: `Transactions`,
                    params
                };
            },
            providesTags: [{ type: 'Transaction' }]
        }),

        getTasksByDate: builder.query({
            query: ({ skip = 0, limit = 20, dateRange, userId = null, ...args }) => {
                let dateFilter = '';
                const params = {
                    $expand: `Transactions($filter=TransDate ge ${dateRange.from} and TransDate le ${dateRange.to} and UserId eq ${userId}),Project($select=Name,AlternativeName)`,
                    $top: limit,
                    count: true,
                    $skip: skip
                };

                if (dateRange) {
                    dateFilter =
                        dateRange.from === dateRange.to
                            ? `Transactions/any(item: item/TransDate date eq ${dateRange.to} and item/UserId eq ${userId})`
                            : `Transactions/any(item: item/TransDate ge ${dateRange.from} and item/TransDate le ${dateRange.to} and item/UserId eq ${userId})`;
                }

                let filters = prepareFilters({
                    filterValue: args?.filterValue,
                    columns: args?.columns,
                    names: NAMES_FOR_FILTER_WEEKLY_LOG,
                    namesString: NAMES_FOR_FILTER_WEEKLY_LOG_TO_STRING
                });

                if (filters) {
                    dateFilter += ` and ${filters}`;
                }

                if (dateFilter) {
                    params['$filter'] = dateFilter;
                }

                let sort = prepareSort({ sortInfo: args?.sortInfo, columns: args?.columns, names: NAMES_FOR_SORT_WEEKLY_LOG });

                if (sort) {
                    params['$orderby'] = sort;
                }

                return {
                    url: `WorkItems`,
                    params
                };
            },
            providesTags: [{ type: 'Transaction' }]
        }),

        getTransactionsByWorkItemId: builder.query({
            query: ({ limit = 5, id }) => {
                return {
                    url: `Transactions`,
                    params: { $filter: `WorkItemId eq ${id}`, $top: limit, $count: true /*$filter: dateFilter*/ }
                };
            },
            providesTags: [{ type: 'Transaction' }]
        }),

        getTransactionOne: builder.query({
            query: ({ id }) => {
                return {
                    url: `Transactions(${id})`,
                    params: { $expand: 'WorkItem($expand=Transactions)' }
                };
            },
            providesTags: [{ type: 'Transaction' }]
        }),

        deleteTransactionOne: builder.mutation({
            query: (id) => {
                return {
                    url: `Transactions(${id})`,
                    method: 'DELETE'
                };
            },
            invalidatesTags: [{ type: 'Transaction' }]
        }),

        getTaskOptions: builder.query({
            query: ({ search, limit = 20 }) => {
                let filter = '';

                if (search) {
                    filter += `(Project/Status ne 'Closed' and Obsolete eq false) and (contains(tolower(Name),'${search}') or contains(tolower(Project/Name),'${search}')`;
                    if (Number(search)) {
                        filter += ` or ExternalId eq ${Number(search)}`;
                    }
                    filter += ')';
                }

                return {
                    url: `WorkItems?${filter ? '$filter=' + filter.trim() : ''}`,
                    params: {
                        $skip: 0,
                        $top: limit,
                        $expand: 'Project($select=Name,AlternativeName,Status)',
                        $select: 'Id,Name,ExternalId',
                        $count: 'true'
                    }
                };
            },
            transformResponse: (response) => {
                if (response?.value) {
                    const value = response.value.map((item) => {
                        return { Name: item.Name, Id: item.Id, Project: item.Project.Name, ExternalId: item?.ExternalId };
                    });
                    return {
                        value,
                        count: response['@odata.count']
                    };
                } else {
                    return false;
                }
            },
            providesTags: [{ type: 'Transaction' }]
        }),
        getTaskOptionOne: builder.query({
            query: (id) => {
                return {
                    url: `WorkItems(${id})`,
                    params: { $expand: 'Project($select=Name)', $select: 'Id,Name,ExternalId' }
                };
            },
            transformResponse: (response) => {
                if (response) {
                    return {
                        Id: response.Id,
                        Name: response.Name,
                        Project: response.Project?.Name,
                        ExternalId: item?.ExternalId
                    };
                } else {
                    return false;
                }
            },
            providesTags: [{ type: 'Transaction' }]
        }),

        getTotalQuantityByDate: builder.query({
            query: ({ dateRange, userId }) => {
                let dateFilter = '';
                if (dateRange) {
                    dateFilter =
                        dateRange.from === dateRange.to
                            ? `TransDate eq ${dateRange.to} and UserId eq ${userId}`
                            : `TransDate ge ${dateRange.from} and TransDate le ${dateRange.to} and UserId eq ${userId}`;
                }
                return {
                    url: `Transactions?$apply=filter(${dateFilter})/aggregate(Quantity with sum as TotalQuantity)`
                };
            }
        }),

        getTotalQuantityByDateAndFilter: builder.query({
            query: ({ dateRange, filterValue, isPosted, userId, columns }) => {
                let dateFilter = '';
                if (dateRange) {
                    dateFilter =
                        dateRange.from === dateRange.to
                            ? `TransDate eq ${dateRange.to} and UserId eq ${userId}`
                            : `TransDate ge ${dateRange.from} and TransDate le ${dateRange.to} and UserId eq ${userId}`;
                }

                if (filterValue?.items?.some((item) => !!item.value)) {
                    let filterValueRes = filterValue?.items?.map((item) => {
                        let value = Array.isArray(item.value)
                            ? item.value.map((el) => el[NAMES_FOR_FILTER_ACTIVITY_LOG[item.field] ? 'id' : 'label'] || 'label')
                            : item.value;
                        if (value?.id) {
                            value = value.id;
                        }
                        if (Array.isArray(item.value) && item.value.length === 1) {
                            value = value.at(0)[NAMES_FOR_FILTER_ACTIVITY_LOG[item.field] ? 'id' : 'label'];
                        }

                        let field = NAMES_FOR_FILTER_ACTIVITY_LOG[item.field] ? NAMES_FOR_FILTER_ACTIVITY_LOG[item.field] : item.field

                        if (Object.keys(stringOperators).includes(item.operator) && NAMES_FOR_FILTER_ACTIVITY_LOG_TO_STRING[item.field]) {
                            field = NAMES_FOR_FILTER_ACTIVITY_LOG_TO_STRING[item.field]
                        }

                        return {
                            ...item,
                            field: field,
                            value: value
                        };
                    });

                    let filterRes = newFilterGenerate({
                        filterValue: filterValueRes,
                        logicOperator: filterValue.logicOperator,
                        columns: columns
                    });
                    if (filterRes) {
                        dateFilter += ` and ${filterRes}`;
                    }
                }

                if (isPosted && dateFilter) {
                    dateFilter += ` and Posted eq true`;
                }

                return {
                    url: `Transactions?$apply=filter(${dateFilter})/aggregate(Quantity with sum as TotalQuantity, Id with countdistinct as TotalRows)`
                };
            },
            providesTags: [{ type: 'Transaction' }]
        }),

        getUsersQuantity: builder.query({
            query: (id) => {
                return {
                    url: `Transactions?$apply=filter(WorkItemId eq ${id})/groupby((User/FullName,Posted),aggregate(Quantity with sum as TotalQuantity))`
                };
            },
            transformResponse: (response) => {
                if (response.value) {
                    let res = [];
                    response.value.forEach((value) => {
                        let existElIndex = res.findIndex((user) => user.User.FullName === value.User.FullName);
                        if (existElIndex !== -1) {
                            if (value.Posted) {
                                res[existElIndex]['Posted'] += value.TotalQuantity;
                                res[existElIndex]['NoPosted'] += value.TotalQuantity;
                            } else {
                                res[existElIndex]['NoPosted'] += value.TotalQuantity;
                            }
                        } else {
                            if (value.Posted) {
                                res.push({ ...value, Posted: value.TotalQuantity });
                            } else {
                                res.push({ ...value, NoPosted: value.TotalQuantity, Posted: 0 });
                            }
                        }
                    });
                    return res;
                } else {
                    return [];
                }
            },
            providesTags: [{ type: 'Transaction' }]
        }),

        getTaskWidtTransactions: builder.query({
            query: ({ id }) => {
                return {
                    url: `WorkItems(${id})`,
                    params: { $expand: `Transactions` }
                };
            },
            providesTags: [{ type: 'Transaction' }]
        }),

        getQuantityOfTransactionsOnTask: builder.query({
            query: ({ taskId, isPosted = false }) => {
                return {
                    url: `Transactions?$apply=filter(WorkItemId eq ${taskId}${isPosted ? ' and Posted eq true' : ''
                        })/groupby((Quantity),aggregate(Quantity with sum as TotalQuantity))`
                };
            },
            transformResponse: (response) => {
                let res = [{ TotalQuantity: 0 }];
                if (response.value) {
                    response.value.forEach((value) => {
                        res[0].TotalQuantity += value.TotalQuantity;
                    });
                    return res;
                } else {
                    return [];
                }
            },
            providesTags: [{ type: 'Transaction' }]
        }),

        getTotal: builder.query({
            query: ({ taskId, isPosted = false }) => {
                return {
                    url: `Transactions?$apply=filter(WorkItemId eq ${taskId}${isPosted ? ' and Posted eq true' : ''
                        })/groupby((Quantity),aggregate(Quantity with sum as TotalQuantity))`
                };
            },
            transformResponse: (response) => {
                let res = [{ TotalQuantity: 0 }];
                if (response.value) {
                    response.value.forEach((value) => {
                        res[0].TotalQuantity += value.TotalQuantity;
                    });
                    return res;
                } else {
                    return [];
                }
            },
            providesTags: [{ type: 'Transaction' }]
        }),

        updateTransactionLog: builder.mutation({
            query: ({ id, body, etag }) => {
                let etagFormatted = etag.replaceAll('\\', '');
                return {
                    url: `Transactions(${id})`,
                    method: 'PATCH',
                    body: body,
                    headers: { 'if-match': etagFormatted }
                };
            },
            invalidatesTags: [{ type: 'Transaction' }]
        }),

        createTransaction: builder.mutation({
            query: ({ body }) => {
                return {
                    url: `Transactions`,
                    method: 'POST',
                    body: { ...body }
                };
            },
            invalidatesTags: [{ type: 'Transaction' }]
        })
    })
});

export const {
    useCreateTransactionMutation,
    useUpdateTransactionLogMutation,
    useGetQuantityOfTransactionsOnTaskQuery,
    useGetUsersQuantityQuery,
    useGetTransactionsWithoutWorkItemQuery,
    useUsersLogTransQuery,
    useGetTotalQuantityByDateAndFilterQuery,
    useGetTransactionsByWorkItemIdQuery,
    useGetTasksByDateQuery,
    useDeleteTransactionOneMutation,
    useGetTransactionsQuery,
    useGetTaskOptionsQuery,
    useGetTaskOptionOneQuery,
    useGetTransactionOneQuery,
    useGetTaskWidtTransactionsQuery,
    useGetTotalQuantityByDateQuery
} = activityLogApi;
