const COLUMN_NAME_TO_REQUEST_NAME = {
    //OLD LOGIC
    Project: 'Project/Name',
    Organization: 'Project/Organization/Name',
    State: 'State/Name',
    Task: 'WorkItem/Name'
};

const dateTimePattern = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

const INLIST = 'inlist';
const NOTINLIST = 'notinlist';

export const OPERATORS_TO_FILTER = {
    contains: ({ entity, value }) => `contains(${entity},${value})`,
    eq: ({ entity, value }) => `${entity} eq ${value}`,
    neq: ({ entity, value }) => `${entity} ne ${value}`,
    ne: ({ entity, value }) => `${entity} ne ${value}`,
    [INLIST]: ({ entity, value }) => `${entity} in (${value.join(',')})`,
    [NOTINLIST]: ({ entity, value }) => `not (${entity} in (${value.join(',')}))`,
    startsWith: ({ entity, value }) => `startswith(${entity},${value})`,
    startswith: ({ entity, value }) => `startswith(${entity},${value})`,
    endsWith: ({ entity, value }) => `endswith(${entity},${value})`,
    endswith: ({ entity, value }) => `endswith(${entity},${value})`,
    gt: ({ entity, value }) => `${entity} gt ${value}`,
    gte: ({ entity, value }) => `${entity} ge ${value}`,
    ge: ({ entity, value }) => `${entity} ge ${value}`,
    lt: ({ entity, value }) => `${entity} lt ${value}`,
    le: ({ entity, value }) => `${entity} le ${value}`,
    lte: ({ entity, value }) => `${entity} le ${value}`,
    after: ({ entity, value }) => `${entity} gt ${value}`,
    afterOrOn: ({ entity, value }) => `${entity} ge ${value}`,
    before: ({ entity, value }) => `${entity} lt ${value}`,
    beforeOrOn: ({ entity, value }) => `${entity} le ${value}`,
    inrange: ({ entity, value }) => `${entity} ge ${value.start} and ${entity} le ${value.end}`,
    notinrange: ({ entity, value }) => `not (${entity} ge ${value.start} and ${entity} le ${value.end})`
};

// const prepareStringValue = (value) => `'${value.toLowerCase().trim()}'`

const prepareStringValue = (value) => {
    try {
        const formatStringReplaceAll = value.toString().toLowerCase().trim().replaceAll("'", "''");

        return `'${formatStringReplaceAll}'`;
    } catch {
        return null
    }
};

const prepareStringEntity = (value) => `tolower(trim(${value}))`;

export const generateFilter = (filters) => {
    let filtersList = [];
    filters.forEach(({ name: entity, operator, value, type, entityPath = null }) => {
        if (value === undefined || (!value && value !== false) || (Array.isArray(value) && value.length === 0)) return;

        if (typeof value === 'object' && (operator === 'inrange' || operator === 'notinrange') && (!value.start || !value.end)) return;

        let entityName = entityPath ? entityPath : COLUMN_NAME_TO_REQUEST_NAME[entity] ? COLUMN_NAME_TO_REQUEST_NAME[entity] : entity;

        if (
            Date.parse(value) &&
            ['eq', 'neq'].includes(operator) &&
            !entityName.toLowerCase().includes('TransDate'.toLowerCase()) &&
            type.includes('date')
        ) {
            const currentDate = Date.parse(value);

            const startDate = new Date(currentDate);
            startDate.setHours(0, 0, 0, 0);

            const endDate = new Date(currentDate);
            endDate.setHours(23, 59, 59, 999);

            const startISO = startDate.toISOString();
            const endISO = endDate.toISOString();

            let filterValue = `${entityName} le ${endISO} and ${entityName} ge ${startISO}`;

            if (operator === 'neq') {
                filterValue = `not (${filterValue})`;
            }
            filtersList.push(filterValue);
        } else if (Array.isArray(value) && value.length > 0) {
            let valueToUse = null;
            if (typeof value.at(0) === 'string' && isNaN(value) && type !== 'date') {
                entityName = prepareStringEntity(entityName);
                valueToUse = value.map((val) => prepareStringValue(val));
            } else if (type === 'date') {
                valueToUse =
                    entityName === 'TransDate' && !(Object.keys(value.at(0)).includes('start') || Object.keys(value.at(0)).includes('end'))
                        ? value.map((val) => val.split('T')[0])
                        : value;
            } else {
                valueToUse = value;
            }

            if (valueToUse) {
                if ((operator === INLIST || operator === NOTINLIST) && valueToUse.length > 1) {
                    filtersList.push(OPERATORS_TO_FILTER[operator]({ entity: entityName, value: valueToUse }));
                } else {
                    let operatorToUse = operator === INLIST ? 'eq' : operator === NOTINLIST ? 'neq' : operator;
                    valueToUse.forEach((val) => {
                        filtersList.push(OPERATORS_TO_FILTER[operatorToUse]({ entity: entityName, value: val }));
                    });
                }
            }
        } else {
            let valToUse = value;

            if (typeof value === 'string' && isNaN(value) && type !== 'date' || type === 'string') {
                entityName = prepareStringEntity(entityName);
                valToUse = prepareStringValue(value);
            }

            if ((Date.parse(value) || (Date.parse(value.start) && Date.parse(value.end))) && type?.includes('date')) {
                if (entityName.includes('TransDate')) {
                    valToUse =
                        typeof value === 'object'
                            ? { start: value.start.split('T')[0], end: value.end.split('T')[0] }
                            : value.split('T')[0];
                } else {
                    valToUse =
                        typeof value === 'object'
                            ? { start: new Date(Date.parse(value.start)).toISOString(), end: new Date(Date.parse(value.end)).toISOString() }
                            : new Date(Date.parse(value)).toISOString();
                }
            }

            let operatorToUse = operator === INLIST ? 'eq' : operator === NOTINLIST ? 'neq' : operator;
            filtersList.push(OPERATORS_TO_FILTER[operatorToUse]({ entity: entityName, value: valToUse }));
        }
    });

    return filtersList.join(' and ');
};


export const numberOperators = {
    '=': 'eq',
    '!=': 'ne',
    '>': 'gt',
    '>=': 'ge',
    '<': 'lt',
    '<=': 'le',
}

export const stringOperators = {
    'equals': 'eq',
    'notEquals': 'ne',
    'contains': 'contains',
    'startsWith': 'startswith',
    'endsWith': 'endswith',
}

export const dateOperators = {
    'is': 'eq',
    'not': 'ne',
    'after': 'gt',
    'onOrAfter': 'ge',
    'before': 'lt',
    'onOrBefore': 'le',
}

export const operatorsFromMuiToOdata = {
    ...numberOperators,
    ...stringOperators,
    ...dateOperators
}


export const filterOperators = (operators) => {
    return operators.filter(el => operatorsFromMuiToOdata?.[el.value]);
}

export const newFilterGenerate = ({ filterValue, logicOperator = 'and', columns = [] }) => {
    const query = [];
    filterValue.forEach((el) => {
        const isDateTimeType = columns.find((col) => col.field === el.field)?.type === 'dateTime';
        const isDateType = columns.find((col) => col.field === el.field)?.type === 'date';

        if (isDateType && new Date(el.value).getYear() < 70) return;
        if (el.value || el.value === false) {
            let resFilter = {};

            let field = el.field;

            if (dateOperators?.[el.operator] && isDateType) {
                const date = new Date(el.value).toISOString()
                resFilter = `${field} ${operatorsFromMuiToOdata[el.operator]} ${date.split('T').at(0)}`
            } else if (dateOperators?.[el.operator] && isDateTimeType) {

                const currentDate = Date.parse(el.value);

                const startDate = new Date(currentDate);
                startDate.setHours(0, 0, 0, 0);

                const endDate = new Date(currentDate);
                endDate.setHours(23, 59, 59, 999);

                const startISO = startDate.toISOString();
                const endISO = endDate.toISOString();
                resFilter = `${field} le ${endISO} and ${field} ge ${startISO}`;
                if (el.operator === 'is') {
                    resFilter = `${field} le ${endISO} and ${field} ge ${startISO}`;
                } else if (el.operator === 'not') {
                    resFilter = `${field} ge ${endISO} or ${field} le ${startISO}`;
                } else if (el.operator === 'after' || el.operator === 'onOrBefore') {
                    const currentDate = Date.parse(el.value);

                    const endDate = new Date(currentDate);
                    endDate.setHours(23, 59, 59, 999);

                    const endISO = endDate.toISOString();

                    resFilter = `${field} ${operatorsFromMuiToOdata[el.operator]} ${endISO}`;
                } else if (el.operator === 'before' || el.operator === 'onOrAfter') {
                    const currentDate = Date.parse(el.value);

                    const startDate = new Date(currentDate);
                    startDate.setHours(0, 0, 0, 0);

                    const startISO = startDate.toISOString();

                    resFilter = `${field} ${operatorsFromMuiToOdata[el.operator]} ${startISO}`;
                } else {
                    resFilter = `${field} ${operatorsFromMuiToOdata[el.operator]} ${new Date(el.value).toISOString()}`;
                }
            } else if (el.value === 'true' || el.value === 'false') {
                resFilter = OPERATORS_TO_FILTER[operatorsFromMuiToOdata[el.operator]]({ entity: field, value: el.value === 'true' ? true : false })
            } else if (Array.isArray(el.value)) {
                resFilter = OPERATORS_TO_FILTER[el.operator === 'is' ? INLIST : NOTINLIST]({ entity: isNaN(el.value.at(0)) ? prepareStringEntity(field) : field, value: isNaN(el.value.at(0)) ? el.value.map((v) => prepareStringValue(v)) : el.value })
            } else if (isNaN(el.value) || stringOperators[el.operator]) {
                resFilter = OPERATORS_TO_FILTER[operatorsFromMuiToOdata[el.operator]]({ entity: prepareStringEntity(field), value: prepareStringValue(el.value) })
            } else {
                resFilter = OPERATORS_TO_FILTER[operatorsFromMuiToOdata[el.operator]]({ entity: field, value: +el.value })
            }

            query.push(resFilter);
        }
    });

    return query.join(` ${logicOperator || 'and'} `);
}

export const prepareFilters = ({ filterValue, names, namesString, columns }) => {
    if (!filterValue?.items.length) return null;
    if (filterValue?.items?.some((item) => !!item.value)) {
        let filterValueRes = filterValue?.items?.map(item => {
            let value = Array.isArray(item.value) ? item.value.map(el => el[names[item.field] ? 'id' : 'label']) : item.value

            if (value?.id) {
                value = value.id
            }

            if (Array.isArray(item.value) && item.value.length === 1) {
                value = value.at(0)
                if (value?.label) {
                    value = value[names[item.field] ? 'id' : 'label']
                }
            }

            let field = names[item.field] ? names[item.field] : item.field

            if (Object.keys(stringOperators).includes(item.operator) && namesString?.[item.field]) {
                field = namesString[item.field]
            }

            return { ...item, field: field, value: value }
        })

        let filterRes = newFilterGenerate({ filterValue: filterValueRes, logicOperator: filterValue.logicOperator, columns: columns })

        if (filterRes) {
            return filterRes
        } else return null
    }

}

export const prepareSort = ({ sortInfo, names }) => {
    if (sortInfo && Object.keys(sortInfo).length > 0) {
        return `${names[sortInfo.field]
            ? names[sortInfo.field]
            : sortInfo.field
            } ${sortInfo.sort}`;
    } else return null
}