// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

import { THEME_LIGHT } from '../../store/reducers/menu';

const Theme = (colors, mode) => {
    const { blue, red, gold, cyan, green, grey } = colors;
    const greyColors = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[7],
        800: grey[8],
        900: grey[9]
    };
    const contrastText = '#fff';

    return {
        primary: {
            lighter: blue[0],
            100: blue[1],
            200: blue[2],
            light: blue[3],
            400: blue[4],
            main: mode === THEME_LIGHT ? blue[5] : blue[7],
            dark: blue[6],
            700: blue[7],
            darker: blue[8],
            900: blue[9],
            contrastText
        },
        secondary: {
            lighter: grey[0],
            100: grey[0],
            200: grey[1],
            light: grey[2],
            400: grey[3],
            main: grey[4],
            600: grey[5],
            dark: grey[6],
            800: grey[7],
            darker: grey[8],
            contrastText: grey[0]
        },
        third:
            mode === THEME_LIGHT
                ? {
                    lighter: '#fffddd',
                    dark: '#fff44b'
                }
                : { lighter: '#2F4F4F', dark: '#fff44b' },
        fourth: {
            main: '#A9A9A9'
        },
        error: {
            lighter: red[0],
            light: red[2],
            main: red[4],
            dark: red[7],
            darker: red[9],
            contrastText
        },
        warning: {
            lighter: gold[0],
            light: gold[3],
            main: gold[5],
            dark: gold[7],
            darker: gold[9],
            contrastText: grey[100]
        },
        info: {
            lighter: cyan[0],
            light: cyan[3],
            main: cyan[5],
            dark: cyan[7],
            darker: cyan[9],
            contrastText
        },
        success: {
            lighter: green[0],
            light: green[3],
            main: green[5],
            dark: green[7],
            darker: green[9],
            contrastText
        },
        grey: greyColors,
        activityLog: {
            postedBG: '#e0ffff',
            postedBGHover: '#ebedf7'
        },
        customColorsStyleCss: {
            colorBG: '#e6f7ff',
            colorBT: '#7986cb',
            colorBgRGBA: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            colorBGGray: 'gray',
            colorBoxShadow: 'rgba(255, 255, 0, 0.5)',
            colorBorder: 'rgb(237, 237, 2)'
        },
        customColorsTable: {
            color: '#1DCD39',
            color1: '#00BE13',
            color2: '#02C2FF',
            color3: 'white'
        },
        customColorsHiddenBox: {
            colorBg: greyColors[200]
        },
        customColorModalTracker: {
            colorBorder: '#ccc',
            colorBorderWhite: 'white',
            colorButtonRed: mode === THEME_LIGHT ? '#F70000' : 'red',
            colorValidComment: '#F17C7C',
            colorInputBg: mode === THEME_LIGHT ? '#F5F5F5' : '#595959',
            colorBorderInput: '#C1C1C1',
            colorTypographyRed: 'red',
            colorTypographyGray: '#B9B9B9',
            colorButtonBgRGBA: 'rgba(250, 210, 113, 0.41)',
            colorButtonBrHoverRGBA: 'rgba(250, 210, 113, 0.3)',
            colorButtonBrDisabledRGBA: 'rgba(250, 210, 113, 0.1)'
        },
        customColorProjectAutComp: {
            colorBg: '#F5F5F5'
        },
        customColorProjectOption: {
            colorTypographyBlack: 'black'
        },
        customColorResizableBox: {
            colorHoverBg: '#3286ED'
        },
        customColorCustomMultFilter: {
            colorBoxBgRGBA: 'rgba(121, 134, 203, 0.4)',
            colorBoxBgRGBAHover: 'rgba(121, 134, 203, 0.2)',
            colorWhiteBg: 'white'
        },
        customColorCustomProjFilter: {
            colorStackBorderT: '#e4e3e2',
            colorButton: '#212427',
            colorMenuItemRGBA: 'rgba(121,134,203,.40)',
            colorProjectFilterCSSBG: 'dodgerblue',
            colorProjectFilterCSSColor: 'white',
            colorProjectFilterCSSBG2: 'rgba(0, 0, 0, 0.1)'
        },
        customColorShareModal: {
            colorBorder: '#ccc'
        },
        customActioPanel: {
            colorButton: mode === THEME_LIGHT ? '#1890FF' : '#65B7F3'
        },
        customColorTableFilter: {
            colorBorderTop: '#e4e3e2',
            colorBG: mode === THEME_LIGHT ? '#fff' : '#141414',
            colorFilterBG: mode === THEME_LIGHT ? '#fff' : '#313131',
            colorBoxBorder: mode === THEME_LIGHT ? '#9C9C9C' : 'white',
            color: mode === THEME_LIGHT ? '#707070' : 'white',
            colorButton: mode === THEME_LIGHT ? '#212427' : 'white',
            colorMenuItemRGBA: 'rgba(121,134,203,.40)',
            colorBoxBorderRGBA: 'rgba(0,0,0,0.3)'
        },
        customColorTableStringFilter: {
            colorBorder: '#e4e3e2',
            colorBgWhite: '#fff',
            colorButton: mode === THEME_LIGHT ? '#212427' : 'white',
            colorMenuItemRGBA: 'rgba(121,134,203,.40)'
        },
        customColorTaskDetails:
            mode === THEME_LIGHT
                ? {
                    colorDrawerBg: '#F8F8F8',
                    colorBoxRGBA: 'rgba(217, 217, 217, 0.5)',
                    colorBlack: '#000000',
                    colorWhite: '#fff',
                    colorValueWithLine: '#555555',
                    colorTypography: '#555555',
                    colorButton: '#C5E9F4',
                    colorButton2: '#00BBF6',
                    colorButton3: '#1DCD3924',
                    colorButton4: '#00BE13'
                }
                : {
                    colorDrawerBg: 'black',
                    colorBoxRGBA: 'rgba(217, 217, 217, 0.1)',
                    colorBlack: '#fff',
                    colorWhite: '#000000',
                    colorValueWithLine: '#bcccca',
                    colorTypography: '#bcccca',
                    colorButton: '#2F4F4F',
                    colorButton2: '#72cfed',
                    colorButton3: '#1DCD3924',
                    colorButton4: '#00BE13'
                },

        customTransactionDetails:
            mode === THEME_LIGHT
                ? {
                    colorModalBg: '#F8F8F8',
                    colorWhite: 'white',
                    colorBlack: '#000000',
                    colorSectionCardRGBA: 'rgba(217, 217, 217, 0.5)',
                    colorTaskSectionBg: '#F5F5F5',
                    colorTaskSectionBorder: '#C1C1C1',
                    colorTaskSectionBgColor: '#6C6B6B',
                    colorTaskSectionTypograp: '#555555',
                    colorCustomTransactionPosted: '#bcdecc',
                    colorCustomTransactionPostedSelected: '#a3cfbb',
                    colorCustomTransactionPostedSelectedHover: '#7dc6a5',
                    colorHoverTransactionBg: '#7dc6a5'
                }
                : {
                    colorModalBg: 'background.paper',
                    colorWhite: 'black',
                    colorBlack: '#b3b9ba',
                    colorSectionCardRGBA: 'rgba(217, 217, 217, 0.2)',
                    colorTaskSectionBg: 'black',
                    colorTaskSectionBorder: '#C1C1C1',
                    colorTaskSectionBgColor: 'white',
                    colorTaskSectionTypograp: 'white',
                    colorCustomTransactionPosted: '#0F5232',
                    colorCustomTransactionPostedSelected: '#327f5a',
                    colorCustomTransactionPostedSelectedHover: '#3e9c6f',
                    colorHoverTransactionBg: '#0B3622'
                },
        customWorkItemSelect: {
            colorBoxShadow: ''
        },
        customColorStartTrackerForm: {
            colorTextField: '#F17C7C'
        },
        customColorTracker: {
            colorIconButton: '#1DCD39',
            colorBoxBg: '#F0F0F0',
            colorTypography: '#757575',
            colorIconButton2: '#555',
            colorWhite: 'white'
        },
        customColorTableComponent: {
            colorColumAlertRGBA: 'rgba(250, 210, 113, 0.41)',
            colorColumAlertHoverRGBA: 'rgba(250, 210, 113, 0.55)',
            colorColumAlertColor: '#262626',
            colorColumAlertOutLine: '#edaa08',
            colorCreateTransaction: '#F17C7C',
            colorTransactionTable: '#3790FF'
        },
        customColorProfile: {
            colorConnectEmailTypography: '#555E68'
        },
        customColorWeekliLog:
            mode === THEME_LIGHT
                ? {
                    colorWeeklyLogBorder: '#E6E6E6',
                    colorHeaderWeekDayBg: '#E6F7FF',
                    colorButtonColor: '#3790FF',
                    colorCellTimeInput: '#1990FF',
                    colorDateTimeBlack: '#000000',
                    colorDateTimeTypography: '#555555',
                    colorTableCellBg: '#FFFDDD',
                    colorTableRowBg: '#f1fbfd',
                    colorTableRowBg2: '#7986CB',
                    colorTableRowBg3: '#D9D9D9',
                    colorButttonBgHover: '#D3EEF8',
                    colorTimeInputBg: '#9edcf7',
                    colorTimeInputColor: '#22e03a',
                    colorTimeInputTextFildBorder: '#C1C1C1',
                    colorTransactionDetails: '#F8F8F8',
                    colorTransactionDetailsBlack: '#000000',
                    colorCastomWeeklyPosted: '#A3CFBB'
                }
                : {
                    colorHeaderWeekDayBg: '#364c61',
                    colorTableCellBg: '#2F4F4F',
                    colorWeeklyLogBorder: '#4e5c5a',
                    colorButtonColor: '#3790FF',
                    colorCellTimeInput: '#1990FF',
                    colorDateTimeBlack: '#000000',
                    colorDateTimeTypography: '#555555',
                    colorTableRowBg: '#2e3635',
                    colorTableRowBg2: '#7986CB',
                    colorTableRowBg3: '#2F4F4F',
                    colorButttonBgHover: '#75807e',
                    colorTimeInputBg: '#1a3559',
                    colorTimeInputColor: '#22e03a',
                    colorTimeInputTextFildBorder: '#C1C1C1',
                    colorTransactionDetails: '#F8F8F8',
                    colorTransactionDetailsBlack: '#000000',
                    colorCastomWeeklyPosted: '#0F5232'
                }
    };
};

export default Theme;
