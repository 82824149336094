import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';
import { useGetWorkItemOptionsQuery } from 'services/task';
import { getGridNumericOperators, GridColumnMenu } from '@mui/x-data-grid';
import ResizableBox from 'components/ResizableBox/index';
import StringFilter from 'components/GridFilters/StringFilter';
import { filterOperators } from 'helpers/changeFilterValueToFilterRequest';

import { DataGridPremium } from '@mui/x-data-grid-premium';
import HeaderFilterWrapper from 'components/GridFilters/HeaderFilterWrapper';
import { useTheme } from '@mui/material/styles';
import Portal from '@mui/material/Portal';

const renderInToolTip = ({ value }) => {
    return (
        <Stack
            sx={{
                pt: '0',
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                justifyContent: 'center',
                '& + .InovuaReactDataGrid__sort-icon-wrapper': { display: 'none !important' }
            }}
        >
            <Tooltip title={value}>
                <Typography sx={{ textOverflow: 'elipsis', overflow: 'hidden' }}>{value}</Typography>
            </Tooltip>
        </Stack>
    );
};

const CustomColumnMenu = (props) => {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuAggregationItem: null
            }}
        />
    );
};

const columns = [
    {
        field: 'ExternalId',
        headerName: 'Ext.ID',
        flex: 1,
        type: 'number',
        filterOperators: filterOperators(getGridNumericOperators()),
        renderCell: renderInToolTip
    },
    {
        field: 'Name',
        headerName: 'Name',
        type: 'string',
        renderHeaderFilter: HeaderFilterWrapper,
        filterOperators: [
            {
                label: 'Contains',
                value: 'contains',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            },
            {
                label: 'Equals',
                value: 'equals',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            },
            {
                label: 'Starts With',
                value: 'startsWith',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            },
            {
                label: 'Ends With',
                value: 'endsWith',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            }
        ],
        // flex: 1,
        renderCell: renderInToolTip
    },
    {
        field: 'Project',
        headerName: 'Project',
        type: 'string',
        renderHeaderFilter: HeaderFilterWrapper,
        filterOperators: [
            {
                label: 'Contains',
                value: 'contains',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            },
            {
                label: 'Equals',
                value: 'equals',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            },
            {
                label: 'Starts With',
                value: 'startsWith',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            },
            {
                label: 'Ends With',
                value: 'endsWith',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            }
        ],
        flex: 1,
        renderCell: renderInToolTip
    },
    {
        field: 'Organization',
        headerName: 'Organization',
        type: 'string',
        renderHeaderFilter: HeaderFilterWrapper,
        filterOperators: [
            {
                label: 'Contains',
                value: 'contains',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            },
            {
                label: 'Equals',
                value: 'equals',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            },
            {
                label: 'Starts With',
                value: 'startsWith',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            },
            {
                label: 'Ends With',
                value: 'endsWith',
                InputComponent: StringFilter,
                getApplyFilterFn: () => ''
            }
        ],
        flex: 1,
        renderCell: renderInToolTip
    }
];

const columnsWithTypes = {};

columns.forEach((el) => (columnsWithTypes[el.field] = el.type));

const DEFAULT_LIMIT = 40;

const ENTER_KEY_CODE = 13;

const WorkItemSelect = ({
    fullWidth = false,
    selectedWorktemId = null,
    debounceMs = 0,
    addElemsOnEndScroll = 40,
    onChange = null,
    autoFocus = false,
    isReturnElemnt,
    onBlurCB,
    bgcolor
}) => {
    const [value, setValue] = useState('');
    const [search, setSearch] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [limit, setLimit] = useState(DEFAULT_LIMIT);
    const [skip, setSkip] = useState(0);
    const [selected, setSelected] = useState(selectedWorktemId ? [selectedWorktemId] : []);
    const [sortInfo, setSortInfo] = useState();
    const filterRef = useRef();
    const tableRef = useRef();
    const isInFocus = useRef(false);
    const countPrev = useRef();
    const isMoreItems = useRef();
    const theme = useTheme();

    const [filterValue, setFilterValue] = useState({ items: [] });
    const [windowSize, setWindowSize] = useState({});

    const { data, isFetching, refetch } = useGetWorkItemOptionsQuery(
        { limit, skip, value: search, filterValue, selectedWorktemId, sortInfo, columnsWithTypes },
        { skip: !isOpen && !selectedWorktemId, refetchOnMountOrArgChange: true }
    );

    // Using to identify element for sizes
    const elementParentIdentity = filterRef?.current?.parentNode?.className;
    // all Data-cells have the same classList ((
    const elementParentIdentityId = filterRef?.current?.parentNode?.parentNode?.dataset?.id;

    const saveWindowSizes = (newItem) => {
        setWindowSize(newItem);
        localStorage.setItem('wrkItemSelectSize', JSON.stringify(newItem));
    };

    useEffect(() => {
        if (selectedWorktemId) {
            refetch({ selectedWorktemId });
        }
        // if (!selectedWorktemId && !isInFocus.current) {
        //     setValue('');
        //     setSearch('');
        // }
    }, [selectedWorktemId]);

    useEffect(() => {
        if (data?.value?.length && data.value.length > 0) {
            if (data.value.length === countPrev.current) {
                isMoreItems.current = false;
            } else {
                isMoreItems.current = true;
            }
            countPrev.current = data.value.length;
        }
        if (data?.value?.length && data.value.length > 0 && selectedWorktemId) {
            setValue(data.value[0].Name);
        }
    }, [data]);

    useEffect(() => {
        if (!isOpen) {
            setLimit(DEFAULT_LIMIT);
            countPrev.current = null;
            isMoreItems.current = true;
        }

        if (isOpen) {
            const x = setTimeout(() => {
                const element = tableRef?.current?.getBoundingClientRect();
                const elementParent = filterRef?.current?.getBoundingClientRect();

                if (!elementParent || !element) return;

                // change comment status on next four rows if don't need to save position on window
                // tableRef.current.style.top = `${windowSize[elementParentIdentity]?.posTop || elementParent.top + elementParent.height}px`;
                const key = elementParentIdentityId || elementParentIdentity;
                tableRef.current.style.left = `${windowSize[key]?.posLeft || elementParent.left}px`;
                tableRef.current.style.top = `${elementParent.top + elementParent.height}px`;
                // tableRef.current.style.left = `${elementParent.left}px`;

                if (element.right > window.innerWidth || elementParent.left + element.right > window.innerWidth) {
                    tableRef.current.style.left = 'unset';
                    tableRef.current.style.right = '10px';
                }

                if (element.width >= window.innerWidth) {
                    tableRef.current.style.left = '10px';
                    tableRef.current.style.width = `${window.innerWidth - 20}px`;
                }

                if (elementParent.top + element.height > window.innerHeight) {
                    tableRef.current.style.top = `${elementParent.top - element.height}px`;
                }
                tableRef.current.style.visibility = `unset`;

                clearTimeout(x);
            }, 0);
        }
    }, [isOpen]);

    useEffect(() => {
        if (autoFocus) setIsOpen(true);
    }, [autoFocus]);

    useEffect(() => {
        const handleClick = (event) => {
            if (
                filterRef.current &&
                !filterRef.current?.contains(event.target) &&
                !tableRef.current?.contains(event.target) &&
                !event.target.closest('.MuiPopover-root') &&
                !event.target.closest('.MuiDataGrid-menu') &&
                !event.target.closest('.MuiDataGrid-panel') &&
                !event.target.closest('.MuiButtonBase-root') &&
                !event.target.closest('.MuiSvgIcon-root')
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleClick);

        // Getting saved positions and sizes
        const wrkItemSelectSize = JSON.parse(localStorage.getItem('wrkItemSelectSize'));
        // const wrkItemSelectSize = JSON.parse(localStorage.getItem('wrkItemSelectSize'));
        if (wrkItemSelectSize) {
            setWindowSize(wrkItemSelectSize);
        }

        return () => {
            handleChangeDebunce.cancel();
            handleScrollDebounce.cancel();
            document.removeEventListener('click', handleClick);
            document.querySelector('body').style.overflow = 'unset';
            countPrev.current = null;
            isMoreItems.current = false;
        };
    }, []);

    const normalizedOptions = data?.value
        ? data.value.map((item) => {
              return {
                  Id: item?.Id,
                  ExternalId: item?.ExternalId,
                  Name: item?.Name,
                  Project: item?.Project?.AlternativeName ? item?.Project?.AlternativeName : item?.Project?.Name,
                  Organization: item?.Project?.Organization?.Name
              };
          })
        : [];

    const handleChangeDebunce = debounce((val) => {
        setSearch((_) => val);
    }, debounceMs);

    const handleScrollDebounce = debounce((event, isMoreItems) => {
        if (isMoreItems) {
            setLimit((prev) => prev + addElemsOnEndScroll);
        }
    }, debounceMs);

    const handleScroll = (event) => {
        handleScrollDebounce(event, isMoreItems.current);
    };

    const updateSearch = useCallback((event) => {
        handleChangeDebunce(event?.target?.value);
    }, []);

    const handleChange = (event) => {
        if (selectedWorktemId) {
            onChange(null);
            setSelected([]);
        }
        setValue(event?.target?.value);
        updateSearch(event);
    };

    const onSelectionChange = (selected) => {
        const id = selected.at(0);
        if (id === selectedWorktemId) {
            onChange(null);
            setValue('');
            setSearch('');
            setIsOpen(false);
            setSelected([]);
        } else if (onChange) {
            setValue('');
            if (isReturnElemnt) {
                onChange(data?.value.find((el) => el.Id === id));
            } else {
                onChange(id);
            }
            setSelected([]);
            setIsOpen(false);
        }
    };

    const handleEnterKey = (e) => {
        if (e?.charCode === ENTER_KEY_CODE && !selectedWorktemId && data?.value?.length && data.value.length === 1) {
            setValue(data.value[0].Name);
            onChange(data.value[0].Id);
            setIsOpen(false);
        }
    };

    const handleESC = (e) => {
        if (e?.keyCode === 27) {
            e.target.blur();
            setIsOpen(false);
        }
    };

    const onFocus = (e) => {
        let x = setTimeout(() => {
            e.target.select();
            clearTimeout(x);
        }, 0);
    };

    const onBlur = () => {
        if (onBlurCB) onBlurCB();
        isInFocus.current = false;
    };

    const onSizeChangeHandler = (size) => {
        const elementPos = tableRef?.current?.getBoundingClientRect();
        const key = elementParentIdentityId || elementParentIdentity;

        const newPosition = {
            posLeft: elementPos.left,
            posTop: elementPos.top
        };

        const newSizes = { ...windowSize };

        newSizes[key] = newPosition;
        newSizes.width = size.width + 'px';
        newSizes.height = +size.height + 52 + 'px';

        saveWindowSizes(newSizes);
    };

    const onColumnResizeChangeHandler = (data) => {
        const tabs = windowSize?.tabSzs ? windowSize.tabSzs : {};

        const tabName = data.colDef.field;
        const tabSize = data.colDef.width;

        tabs[tabName] = tabSize;
        windowSize.tabSzs = tabs;

        saveWindowSizes({ ...windowSize });
    };

    // updating array for tabs custom sizes.
    columns.forEach((c) => {
        const customSize = windowSize?.tabSzs;

        if (customSize && customSize[c.field]) {
            c.flex = undefined;
            c.width = customSize[c.field];
        } else {
            c.flex = 1;
            c.width = undefined;
        }
    });

    return (
        <Box
            sx={{ maxWidth: fullWidth ? '100%' : '200px', width: '100%', position: 'relative' }}
            ref={filterRef}
            onKeyPress={handleEnterKey}
        >
            <TextField
                sx={{ zIndex: 1 }}
                value={value}
                onKeyDown={handleESC}
                onChange={handleChange}
                autoFocus={autoFocus}
                fullWidth
                label="Enter Task Name or External ID"
                name="Work Item Select"
                type="text"
                autoComplete="off"
                variant="standard"
                onFocus={onFocus}
                onBlur={onBlur}
                onClick={() => setIsOpen(true)}
            />
            {isOpen && (
                <Portal>
                    <ResizableBox
                        ref={tableRef}
                        tabIndex="0"
                        onClick={(e) => e.stopPropagation()}
                        onKeyDown={handleESC}
                        sx={{
                            width: `${windowSize?.width || '30svw'}`,
                            height: `${windowSize?.height || '300px'}`,
                            position: 'fixed',
                            backgroundColor: bgcolor || 'background.paper',
                            boxShadow: theme.customShadows.z2,
                            zIndex: '1000',
                            visibility: 'hidden'
                        }}
                    >
                        <DataGridPremium
                            disableRowGrouping
                            disableAggregation
                            sx={{ border: 'none' }}
                            columns={columns}
                            loading={isFetching}
                            rows={normalizedOptions || []}
                            rowSelectionModel={selected}
                            getRowId={(el) => el?.Id}
                            hideFooterPagination={true}
                            onSortModelChange={(val) => setSortInfo(val?.[0] || null)}
                            filterMode="server"
                            sortingMode="server"
                            filterDebounceMs={300}
                            filterModel={filterValue}
                            onFilterModelChange={(val) => {
                                countPrev.current = 0;
                                isMoreItems.current = true;
                                setLimit(DEFAULT_LIMIT);
                                setFilterValue(val);
                            }}
                            slots={{ columnMenu: CustomColumnMenu }}
                            onRowsScrollEnd={handleScroll}
                            headerFilters
                            // selected={selectedWorktemId ? selectedWorktemId : null}
                            onRowSelectionModelChange={!isFetching ? onSelectionChange : () => {}}
                            onResize={onSizeChangeHandler}
                            onColumnResize={onColumnResizeChangeHandler}
                        />
                    </ResizableBox>
                </Portal>
            )}
        </Box>
    );
};

export default WorkItemSelect;
