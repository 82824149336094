// material-ui
import { Box, useMediaQuery, Typography } from '@mui/material';

// project import
import Profile from './Profile';
import Notifications from './notification/Notifications';
import MobileSection from './MobileSection';
import { Tracker } from './Tracker';
import { useSelector, useDispatch } from 'react-redux';
import useHasPrivileges, { ENTITIES, PRIVILEGES } from 'hooks/useHasPrivileges';
import useUserProfileSettings from 'hooks/useUserProfileSettings';
import {styled} from "@mui/material/styles";

// ==============================|| HEADER - CONTENT ||============================== //
const StyledBox = styled(Box)(({ theme }) => ({
    width: '100%',
    marginLeft: '20px',
    [theme.breakpoints.down('lg')]: {
        maxWidth: '125px',
    },
    [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
    },
}));

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const pageName = useSelector((state) => state.menu.pageName);
    const { data, isFetching } = useUserProfileSettings();

    const { isHasPrivilege } = useHasPrivileges({ entity: 'TrackedTransaction', privilege: PRIVILEGES.SELECT })

    return (
        <>
            <StyledBox>
                <Typography variant="h5">{pageName}</Typography>
            </StyledBox>
            {!matchesXs && isHasPrivilege && !isFetching && data?.isShowTracker && (
                <Box sx={{ mx: '10px', width: '100%' }}>
                    <Tracker />
                </Box>
            )}
            {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

            <Notifications />
            {!matchesXs && <Profile />}
            {matchesXs && <MobileSection />}
        </>
    );
};

export default HeaderContent;
